import { useContext, useRef, useMemo, useEffect } from "react";

// Third-party
import * as Sentry from "@sentry/browser";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

// Context
import { Map3DContext } from "Map3DProvider";
import { useLocalSettingsContext } from "context/LocalSettingsContext";
import { useUiContext } from "context/UiContext";

// Utils
import {
  calculateElevations,
  retirevePylonModel,
  retrievePathSegments,
  retrievePylonConnectors,
  calculatePylonPositions,
  generatePylonPairs,
  calculateOverheadCablePointsWithCatenary,
  calculateUndergroundPoints,
  renderTunnel,
  renderUndergroundLine,
  addEntityAuxLine,
  addEntityPylon,
  addEntityOverheadCable,
  addEntityNoPylonsLine,
  retrievePathTransitions,
  addEntityRectangleTransitionArea,
  retirevePylonModels,
  calculateOverheadCablePointsWithSimpleCatenary
} from "utils/Cesium/CesiumPathsUtils";
import { geographicToCartesian3D } from "utils/Cesium/CesiumCoordinatesUtils";
import {
  changePolylineMaterialByName,
  removeEntityByCondition,
  removeEntityByNameAndProperty,
  setEntityAuxLineVisiblity,
  setEntityEllipseVisiblity,
  setEntityVisiblityByProperty
} from "utils/Cesium/CesiumEntitiesUtils";
import { getDatasourceLayerByName } from "utils/Cesium/CesiumDatasourceUtils";
import { getNode } from "utils/PathWrapper";

// Constants & Other
import { optimalPathVectorName, towerName } from "assets/global";

const useCesiumPath = (availableTechnologies, availableVariants) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [uiState, { setPylonHighlightActive, setPathHighlightActive }] =
    useUiContext();

  const { localSettingsState } = useLocalSettingsContext();
  const map3DContext = useContext(Map3DContext);

  const isPylonsSelectionActive = useRef(false);
  const isPathSelectionActive = useRef(false);

  const viewer = useMemo(
    () => map3DContext?.state?.cesiumViewer,
    [map3DContext?.state?.cesiumViewer]
  );

  /**
   * Effect to update the references to the selection states.
   */
  useEffect(() => {
    isPathSelectionActive.current = uiState.pathHighlightActive;
    isPylonsSelectionActive.current = uiState.pylonHighlightActive;
  }, [uiState.pathHighlightActive, uiState.pylonHighlightActive]);

  /**
   * Toggle path visibility of existing paths in the viewer.
   *
   * @param {number} pathId Id of the path.
   * @param {boolean} visible Visibility of the path.
   */
  const togglePathVisibility = (pathId, visible) => {
    try {
      let pathSource = getDatasourceLayerByName(viewer, optimalPathVectorName);

      if (!pathSource || !pathSource.entities) {
        console.error(`Path source not found for pathId: ${pathId}`);
        return;
      }

      setEntityVisiblityByProperty(
        viewer,
        pathSource.entities,
        "group",
        pathId,
        visible
      );

      removeEntityByCondition(viewer, pathSource.entities, "group", pathId);

      removeEntityByNameAndProperty(
        viewer,
        pathSource.entities,
        "auxline",
        "pathId",
        pathId
      );

      removeEntityByNameAndProperty(
        viewer,
        pathSource.entities,
        "flatline",
        "pathId",
        pathId
      );

      viewer.scene.requestRender();
    } catch (error) {
      console.error("Error in togglePathVisibility:", error);
    }
  };

  async function viewPath(path, color) {
    // Get the segments of the path to render.
    const segments = retrievePathSegments(path, "TECH");
    if (!segments) return;

    // Calculate elevation for each point in the path.
    const fullPathElevations = await calculateElevations(
      path.path.coordinates,
      map3DContext.state.terrainAsset
    );

    // We keep a copy of path elevations without the start and end
    // nodes to use when rendering the overhead lines.
    const pathElevationsWithoutStartEnd = fullPathElevations;

    // TRANSITIONS:
    // Get all the transitions in the path (all segments).
    const transitions = retrievePathTransitions(segments);

    for (let index = 0; index < transitions.length; index++) {
      const transition = transitions[index];
      const fromNode = getNode(path, path.metadata, transition.from);
      const toNode = getNode(path, path.metadata, transition.to);

      addEntityRectangleTransitionArea(
        viewer,
        path.id,
        fromNode,
        toNode,
        map3DContext.state.terrainAsset,
        color
      );
    }

    // Iterate the segments to render each one.
    for (let index = 0; index < segments.length; index++) {
      const segment = segments[index];

      switch (segment.type) {
        case "O":
        case "Overhead": {
          // RENDER an auxline to highlight the path when the
          // add pylon tool is active.
          const auxLinePoints = await geographicToCartesian3D(
            segment.coordinates,
            map3DContext.state.terrainAsset
          );
          addEntityAuxLine(
            auxLinePoints,
            { pathId: segment.pathId },
            { isSelected: isPathSelectionActive.current },
            viewer
          );
          addEntityNoPylonsLine(
            auxLinePoints,
            color,
            { pathId: segment.pathId },
            { showPylons: localSettingsState.localSettings.showPylons },
            viewer
          );

          // Retrieve the pylon model for the segment.
          const [pylonModel, isDefault] = await retirevePylonModel(
            segment,
            "VARIANT",
            availableTechnologies,
            availableVariants
          );

          if (isDefault) {
            enqueueSnackbar(t("Technologies.NoPylon", { sectionIdx: "" }), {
              variant: "warning"
            });
          }

          // RENDER the pylons of the segment.
          const pylonsData = await calculatePylonPositions(
            segment.coordinates,
            map3DContext.state.terrainAsset
          );

          const pylonModels = await retirevePylonModels(
            segment,
            availableVariants
          );

          pylonsData.pylonModels = pylonModels;

          // In case of overhead after tunnel, we skip the first pylon.
          let index = segment.tunnelExit ? 1 : 0;

          for (index; index < pylonsData.cartesian3DPositions.length; index++) {
            addEntityPylon(
              pylonsData.pylonModels[index],
              pylonsData.cartesian3DPositions[index],
              pylonsData.orientations[index],
              {
                pathId: segment.pathId,
                techIndex: index,
                name: towerName,
                globalIndex: segment.globalIdxStart + index,
                serial: index
              },
              {
                showPylons: localSettingsState.localSettings.showPylons,
                isSelected: isPylonsSelectionActive.current
              },
              viewer
            );
          }

          // Retreive all pylon pairs in the segment.
          const pylonPairs = generatePylonPairs(
            pylonsData,
            segment.globalIdxStart
          );

          // Iterate all pylon pairs to calculate and render
          // the cables in between.
          // In case of overhead after tunnel, we skip the first pair.
          index = segment.tunnelExit ? 1 : 0;
          for (index; index < pylonPairs.length; index++) {
            const pylonPair = pylonPairs[index];

            // Flag to mark the las pair to calculate the connectors
            // to the last pair differently.
            const isLast = index === pylonPairs.length - 1;

            // Iterate the conectors of the pylons to
            // calculate the cable points and then render them.
            for (
              let connectorIndex = 0;
              connectorIndex < pylonPair.fromNode.connectors.length;
              connectorIndex++
            ) {
              const catenaryPoints = calculateOverheadCablePointsWithCatenary(
                pylonPair,
                pathElevationsWithoutStartEnd,
                connectorIndex,
                pylonPair.fromNode.model,
                isLast
              );

              // const catenaryPoints =
              //   calculateOverheadCablePointsWithSimpleCatenary(
              //     pylonPair.fromNode.geographicPosition,
              //     pylonPair.toNode.geographicPosition,
              //     pylonPair.fromNode.bearing,
              //     pylonPair.toNode.bearing,
              //     pylonPair.fromNode.connectors[connectorIndex]
              //       .crossarmConnectorShift,
              //     pylonPair.toNode.connectors[connectorIndex]
              //       .crossarmConnectorShift,
              //     pylonPair.fromNode.connectors[connectorIndex]
              //       .crossarmConnectorElevation,
              //     pylonPair.toNode.connectors[connectorIndex]
              //       .crossarmConnectorElevation,
              //     fullPathElevations[pylonPair.fromNode.globalIdx].height,
              //     fullPathElevations[pylonPair.toNode.globalIdx].height,
              //     pylonPair.fromNode.model,
              //     pylonPair.toNode.model,
              //     isLast
              //   );
              addEntityOverheadCable(
                catenaryPoints,
                color,
                {
                  pathId: segment.pathId,
                  wireIndex: connectorIndex,
                  serial: connectorIndex,
                  name: "line"
                },
                { showPylons: localSettingsState.localSettings.showPylons },
                viewer
              );
            }
          }
          break;
        }
        case "U":
        case "Earth Cable": {
          // Process the segment points.
          const pipePoints = await calculateUndergroundPoints(
            segment.coordinates,
            map3DContext.state.terrainAsset,
            5,
            -5
          );

          // Render the pipe (earth cable/underground) segment.
          renderUndergroundLine(
            pipePoints,
            color,
            "square",
            {
              pathId: segment.pathId,
              techIndex: segment.techIdx
            },
            viewer
          );
          break;
        }
        case "T":
        case "Tunnel": {
          // Process the segment points.
          const tunnelPoints = await geographicToCartesian3D(
            segment.coordinates,
            map3DContext.state.terrainAsset
          );

          // Render the tunnel segment.
          renderTunnel(
            tunnelPoints,
            color,
            {
              pathId: segment.pathId,
              techIndex: segment.techIdx
            },
            viewer
          );
          break;
        }
        default:
          break;
      }
    }
  }

  /**
   * Method to remove a path from the globe.
   *
   * @param {Number} pathId Id of the path to hide.
   */
  function hidePath(pathId) {
    togglePathVisibility(pathId, false);
  }

  /**
   * Method to change the path color.
   *
   * @param {Number} pathId The path id.
   * @param {String} color Hexadecimal color.
   */
  function setPathColor(pathId, color) {
    let optimalPathVectorSource = getDatasourceLayerByName(
      map3DContext.viewer,
      optimalPathVectorName
    );

    let entities = optimalPathVectorSource.entities;
    changePolylineMaterialByName(entities, pathId, color);

    map3DContext.viewer.scene.requestRender();
  }

  /**
   * Method to turn on and off the highlighting of pylons.
   *
   * @param {Boolean} value If true, the ellipsi in the pylons will be visible.
   */
  function highlightPylons(value) {
    isPylonsSelectionActive.current = value;
    setPylonHighlightActive(value);
    const pathSource = getDatasourceLayerByName(viewer, optimalPathVectorName);

    setEntityEllipseVisiblity(viewer, pathSource.entities, towerName, value);

    map3DContext.viewer.scene.requestRender();
  }

  /**
   * Method to turn on and off the highlighting of overhead lines.
   *
   * @param {Boolean} value If true, the aux line of the path will be visible.
   */
  function highlightOverhead(value) {
    isPathSelectionActive.current = value;
    setPathHighlightActive(value);

    const pathSource = getDatasourceLayerByName(viewer, optimalPathVectorName);

    setEntityAuxLineVisiblity(viewer, pathSource.entities, value);

    map3DContext.viewer.scene.requestRender();
  }
  return {
    showPath: viewPath,
    hidePath: hidePath,
    setPathColor: setPathColor,
    highlightPylons: highlightPylons,
    highlightOverhead: highlightOverhead
  };
};

export default useCesiumPath;

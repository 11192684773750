// Cesium
import {
  Cartesian3,
  Cartographic,
  HeightReference,
  Color,
  Cartesian2,
  Transforms,
  CornerType,
  CallbackProperty,
  HeadingPitchRoll,
  Math as CesMath,
  DistanceDisplayCondition,
  CesiumTerrainProvider,
  PolylineDashMaterialProperty,
  ColorMaterialProperty,
  ColorBlendMode,
  PolygonHierarchy,
  ClippingPlane
} from "cesium/Build/Cesium/Cesium";

// Third-Party
import axios from "axios";
import {
  point,
  bearing,
  transformTranslate,
  distance,
  getType,
  midpoint,
  destination
} from "@turf/turf";

// Utils
import {
  geographicToCartesian3D,
  getCesiumElevatonPoints
} from "utils/Cesium/CesiumCoordinatesUtils";
import { getDatasourceLayerByName } from "utils/Cesium/CesiumDatasourceUtils";
import { getColorByString, getOrientation } from "utils/Cesium/CesiumUtils";
import {
  getPathSections,
  getPathTechnologies,
  getVariants
} from "utils/PathWrapper";

// Services
import { fetchTechnology, fetchVariant } from "services/catalog";

// Other
import { optimalPathVectorName } from "assets/global";

// Utility Methods
/**
 * Method to render a circle. It can be used to
 * render a solid tunnel for instance.
 *
 * @param {number} radius The radius of the circle.
 *
 * @returns {array} Positions of the circle
 */
function computeCircle(radius) {
  const positions = [];
  for (let i = 0; i < 360; i++) {
    const radians = CesMath.toRadians(i);
    positions.push(
      new Cartesian2(radius * Math.cos(radians), radius * Math.sin(radians))
    );
  }
  return positions;
}

function computeCatenary(model, distanceKms, numSegments, last = false) {
  if (numSegments == 1) return [0];

  const minCrossarmHeight = Math.min(
    ...model.metadata.lines.map(line => line[0])
  );

  let distance = 0.5 * distanceKms * 1000; // we take half section and duplicate it

  let Hmin = model.metadata.minimum_distance_to_ground;
  // let Hl = model.metadata.height;
  let Hl = minCrossarmHeight;

  let array = [],
    temp = [];
  let k = Math.acosh(Hl / Hmin) / distance;

  for (let q = 0; q < numSegments; q++) {
    let x = (distance * q) / numSegments;
    let h = Hmin * Math.cosh(k * x);

    array.push(Hl - h);
  }
  array.push(0);
  temp = [...array];
  array.reverse();

  return array.concat(temp);
}

/**
 * This is the catenary function implemented:
 *      y = aParam * (cosh((x1 - x0) / aParam)) + y0
 *
 * @param {number} distance Distance between start and end points
 * @param {number} heightA Height of point A
 * @param {number} heightB Height of point B
 * @param {number} aParam A parameter (check docuementation)
 * @param {number} numSegments Number of points to sample
 */
export function computeCatenaryHeights(
  distance,
  heightA,
  heightB,
  aParam,
  numSegments
) {
  // Method to calculate the the difference between the catenary with
  // parameters a, x0 and y0 and the known start points x1, y1 and x2, y2.
  const catenaryError = (aParam, x0, y0, x1, x2, y1, y2) => {
    const y1p = aParam * Math.cosh((x1 - x0) / aParam) + y0;
    const y2p = aParam * Math.cosh((x2 - x0) / aParam) + y0;
    const error1 = y1p - y1;
    const error2 = y2p - y2;
    return {
      errorAbs: Math.abs(error1) + Math.abs(error2),
      errorSum: error1 + error2,
      errorDif: error1 - error2
    };
  };

  const distanceHalf = distance * 0.5;
  const x1 = -distanceHalf;
  const x2 = distanceHalf;
  const minHeight = Math.min(heightA, heightB);

  // Initial values
  let x0 = 0;
  let y0 = -aParam + minHeight;

  // Initial search radius for x0 and y0
  let xRad = 0.001 * distanceHalf;
  let yRad = 1.0;

  // Baseline errors
  let { errorAbs, errorSum, errorDif } = catenaryError(
    aParam,
    x0,
    y0,
    x1,
    x2,
    heightA,
    heightB
  );

  // Alternate x and y approximations.
  let reduceX = true;

  while (xRad > 0.001 && errorAbs > 0.1) {
    // calculate candidate
    const candX = x0 - xRad * errorDif;
    const candY = y0 - yRad * errorSum;
    const cand_errors = catenaryError(
      aParam,
      candX,
      candY,
      x1,
      x2,
      heightA,
      heightB
    );
    const candAbs = cand_errors.errorAbs;
    const candSum = cand_errors.errorSum;
    const candDif = cand_errors.errorDif;

    if (candAbs < errorAbs) {
      x0 = candX;
      y0 = candY;
      errorAbs = candAbs;
      errorSum = candSum;
      errorDif = candDif;
      continue;
    }
    // reduce search steps
    if (reduceX) {
      xRad = xRad / 2;
    } else {
      yRad = yRad / 2;
    }
    reduceX = !reduceX;
  }

  const heights = [];
  for (let q = 0; q < numSegments; q++) {
    let x = x1 + ((x2 - x1) * q) / (numSegments - 1);
    heights.push(aParam * Math.cosh((x - x0) / aParam) + y0);
  }

  return heights;
}

/**
 * Function to interpolate points between given positions.
 * It can be used to add points to an earth cable, so it
 * will better follow the terrain.
 *
 * @param {array} points Original array of points (coordinates x,y).
 * @param {number} numInterpolatedPoints Amount of points to interpolate.
 *
 * @returns {array} The array containing the interpolated points.
 */
function interpolatePoints(points, numInterpolatedPoints) {
  const interpolatedPoints = [];
  for (let i = 0; i < points.length - 1; i++) {
    const start = points[i];
    const end = points[i + 1];
    interpolatedPoints.push(start);

    for (let j = 1; j <= numInterpolatedPoints; j++) {
      const fraction = j / (numInterpolatedPoints + 1);
      const lon = start[0] + (end[0] - start[0]) * fraction;
      const lat = start[1] + (end[1] - start[1]) * fraction;
      interpolatedPoints.push([lon, lat]);
    }
  }
  interpolatedPoints.push(points[points.length - 1]);

  return interpolatedPoints;
}

function tunnelShape(type, data = {}) {
  switch (type) {
    case "hollow-cylinder": {
      const outerPositions = [];
      const innerPositions = [];

      for (let i = 0; i < 360; i++) {
        const radians = CesMath.toRadians(i);
        outerPositions.push(
          new Cartesian2(
            data?.outerRadius * Math.cos(radians),
            data?.outerRadius * Math.sin(radians)
          )
        );
        innerPositions.push(
          new Cartesian2(
            data?.innerRadius * Math.cos(radians),
            data?.innerRadius * Math.sin(radians)
          )
        );
      }

      // Add the first point again at the end to close the loop
      outerPositions.push(outerPositions[0]);
      innerPositions.push(innerPositions[0]);

      // The outer circle is counterclockwise, the inner circle needs to be clockwise
      return outerPositions.concat(innerPositions.reverse());
    }

    default:
      break;
  }
}

function pipeShape(type) {
  switch (type) {
    case "square": {
      const width = 2; // Half the total width
      const height = 2; // Half the total height
      const bottomLeft = new Cartesian2(-width / 2, -height);
      const bottomRight = new Cartesian2(width / 2, -height);
      const topRight = new Cartesian2(width / 2, 0);
      const topLeft = new Cartesian2(-width / 2, 0);

      return [bottomLeft, bottomRight, topRight, topLeft];
    }

    default:
      break;
  }
}

/**
 * Method to render a hollow circle. It can be used to
 * render a hollow tunnel for instance.
 *
 * @param {number} outerRadius The outer radius of the circle.
 * @param {number} innerRadius The inner radius of the circle.
 *
 * @returns {array} Positions of the hollow circle
 */
function computeHollowCircle(outerRadius, innerRadius) {
  const outerPositions = [];
  const innerPositions = [];

  for (let i = 0; i < 360; i++) {
    const radians = CesMath.toRadians(i);
    outerPositions.push(
      new Cartesian2(
        outerRadius * Math.cos(radians),
        outerRadius * Math.sin(radians)
      )
    );
    innerPositions.push(
      new Cartesian2(
        innerRadius * Math.cos(radians),
        innerRadius * Math.sin(radians)
      )
    );
  }

  // Add the first point again at the end to close the loop
  outerPositions.push(outerPositions[0]);
  innerPositions.push(innerPositions[0]);

  // The outer circle is counterclockwise, the inner circle needs to be clockwise
  return outerPositions.concat(innerPositions.reverse());
}

/**
 * This is an auxiliary function for the clipping planes. This function
 * may involve sampling the terrain and creating the normal,
 * which can be complex and require CesiumJS's terrain provider methods.
 * If the terrain is flat, `Cartesian3.UNIT_Z` is sufficient in most cases.

 * @returns
 */
function getNormalAtPosition() {
  return Cartesian3.UNIT_Z;
}

/**
 * Auxiliary method to calculate the clipping planes when rendering
 * tunnels clipped by terrain. (Not used yet).
 *
 * @param {object} viewer Target Cesium viewer.
 * @param {array} positions Array of postions (tunnel).
 * @param {number} tunnelDepth How deep is the tunnel.
 *
 * @returns
 */
function createClippingPlanes(viewer, positions, tunnelDepth) {
  const clippingPlanes = [];
  for (let i = 0; i < positions.length - 1; i++) {
    const start = positions[i];
    const end = positions[i + 1];
    const direction = Cartesian3.subtract(end, start, new Cartesian3());
    Cartesian3.normalize(direction, direction);
    const midpoint = Cartesian3.midpoint(start, end, new Cartesian3());

    let up = Cartesian3.UNIT_Z;

    const hasTerrain = viewer.terrainProvider instanceof CesiumTerrainProvider;

    if (hasTerrain) {
      up = getNormalAtPosition(midpoint, viewer.terrainProvider);
    }

    const right = Cartesian3.cross(direction, up, new Cartesian3());
    Cartesian3.normalize(right, right);
    clippingPlanes.push(new ClippingPlane(right, tunnelDepth));
  }
  return clippingPlanes;
}
/**
 * Method to render an spherical connector of a given size and
 * in a given depth.
 *
 * @param {object} viewer Target Cesium viewer.
 * @param {object} position Position of the sphere.
 * @param {number} size Radius of the sphere.
 * @param {number} depth Depth where the sphere will sit.
 * @param {string} color Color of the sphere.
 */
async function renderSphereConnector(
  viewer,
  position,
  size = 10,
  depth = 15,
  color = "rgba(255,0,0,1)"
) {
  try {
    const { longitude, latitude, height } = position;
    const center = Cartesian3.fromRadians(longitude, latitude, height - depth);

    const pathSource = getDatasourceLayerByName(viewer, optimalPathVectorName);

    if (!pathSource) {
      throw new Error(
        `DataSource layer named ${optimalPathVectorName} not found`
      );
    }

    let optimalPathVectorEntities = pathSource.entities;

    // You can comment this out if you encounter issues with it
    optimalPathVectorEntities.suspendEvents();
    const radius = size;
    let material = color;
    if (color !== Color.BLACK) material = getColorByString(color);
    // Create a box entity
    optimalPathVectorEntities.add({
      name: "Connector",
      position: center,
      ellipsoid: {
        radii: new Cartesian3(radius, radius, radius),
        material: material
      }
    });

    optimalPathVectorEntities.resumeEvents(); // Resume events if it was suspended previously

    viewer.scene.requestRender();
  } catch (error) {
    console.error("Error in drawCubeConnector:", error);
  }
}

// Feature methods
export function getPathSourceEnities(viewer) {
  const pathSource = getDatasourceLayerByName(viewer, optimalPathVectorName);
  if (!pathSource) {
    return new Error(
      `DataSource layer named ${optimalPathVectorName} not found`
    );
  } else {
    return pathSource.entities;
  }
}

/**
 * Method that given a path object it extracts the segments
 * based on the given type (TYPE, TECH or VARIANT).  Depending on
 * the selected type it will process the path accordingly.
 *
 * Each segment will have its sliced coordinates, metadata and
 * global indexes, besides the technology info.
 *
 * @param {object} path The path to retrieve segments from.
 * @param {string} type The type of segments.
 *
 * @returns {array} Array of segments by type.
 */
export function retrievePathSegments(path, mode) {
  switch (mode) {
    case "TYPE":
      return getPathSections(path, path.metadata);

    case "TECH": {
      let technologies = getPathTechnologies(path, path.metadata);

      // Because getPathTechnologies returns for each segment
      // the nodes of the segment + the 1st node of the next
      // segment, we do the following update to remove the
      // last node of the segment, unless it's a tunnel.
      let updatedTechnologies = [];
      for (let index = 0; index < technologies.length; index++) {
        const tech = technologies[index];

        const { coordinates, metadata } = tech;

        let newCoordinates = [];
        let newMetadata = [];

        if (tech.type === "Tunnel" || tech.type === "T") {
          // If the technology of the section is a Tunnel, we
          // include the next node in the coordinates and metadata.
          newCoordinates = coordinates;
          newMetadata = metadata;
        } else {
          const start = index === 0 ? 1 : 0;
          // Otherwise, we only use the current technology nodes.
          newCoordinates = coordinates.slice(start, -1);
          newMetadata = metadata.slice(start, -1);
        }

        // Push the new coordinates and meta together with the
        // segment type (overhead, tunnel or earth cable) and the path id.
        updatedTechnologies.push({
          type: tech.type,
          tech: tech.name,
          pathId: tech.pathId,
          coordinates: newCoordinates,
          metadata: newMetadata
        });
      }

      // Finished iterating technologies and now we
      // add the global indexes to the segments.
      const segmentsWithIdx = [];
      let globalIdx = 1;
      for (let index = 0; index < updatedTechnologies.length; index++) {
        let segment = updatedTechnologies[index];

        // Set a flag if the segment is overhead after a tunnel.
        let prevSegment = updatedTechnologies[index - 1];
        if (
          (segment.type === "Overhead" || segment.type === "O") &&
          (prevSegment?.type === "Tunnel" || prevSegment?.type === "T")
        ) {
          segment.tunnelExit = true;
        } else {
          segment.tunnelExit = false;
        }

        // Add indexes.
        segment.segmentIdx = index;
        segment.techIdx = index;
        segment.globalIdxStart = globalIdx;
        segmentsWithIdx.push(segment);

        // Calculate semgment length to calculate the global index.
        // In case of tunnel, we substract 1 because the tunnels
        // have 2 coordinates for rendering purposes, but one coordinate
        // belongs to the next segment.
        let segmentLength;
        if (segment.type === "Tunnel" || segment.type === "T") {
          segmentLength = segment.coordinates.length - 1;
        } else {
          segmentLength = segment.coordinates.length;
        }

        globalIdx = globalIdx + segmentLength;
      }

      return segmentsWithIdx;
    }

    case "VARIANT": {
      const variants = getVariants(path, path.metadata);

      // Because getPathTechnologies returns for each segment
      // the nodes of the segment + the 1st node of the next
      // segment, we do the following update to remove the
      // last node of the segment, unless it's a tunnel.
      let updatedVariants = [];

      for (let index = 0; index < variants.length; index++) {
        const variant = variants[index];
        const { coordinates, metadata, ...rest } = variant;

        let newCoordinates = [];
        let newMetadata = [];

        if (variant.type === "Tunnel" || variant.type === "T") {
          // If the technology of the section is a Tunnel, we
          // include the next node in the coordinates and metadata.
          newCoordinates = coordinates;
          newMetadata = metadata;
        } else {
          // Otherwise, we only use the current technology nodes.
          newCoordinates = coordinates.slice(0, -1);
          newMetadata = metadata.slice(0, -1);
        }

        updatedVariants.push({
          ...rest,
          coordinates: newCoordinates,
          metadata: newMetadata
        });
      }

      // Add the global indexes to the segments.
      const segmentsWithIdx = [];
      let globalIdx = 0;
      for (let index = 0; index < updatedVariants.length; index++) {
        let segment = updatedVariants[index];

        // Set a flag if the segment is overhead after a tunnel.
        let prevSegment = updatedVariants[index - 1];
        if (
          (segment.type === "Overhead" || segment.type === "O") &&
          (prevSegment?.type === "Tunnel" || prevSegment?.type === "T")
        ) {
          segment.tunnelExit = true;
        } else {
          segment.tunnelExit = false;
        }

        segment.segmentIdx = index;
        segment.techIdx = index;
        segment.globalIdxStart = globalIdx;
        segmentsWithIdx.push(segment);

        // Calculate semgment length to calculate the global index.
        // In case of tunnel, we substract 1 because the tunnels
        // have 2 coordinates for rendering purposes, but one coordinate
        // belongs to the next segment.
        let segmentLength;
        if (segment.type === "Tunnel" || segment.type === "T") {
          segmentLength = segment.coordinates.length - 1;
        } else {
          segmentLength = segment.coordinates.length;
        }

        globalIdx = globalIdx + segmentLength;
      }
      return segmentsWithIdx;
    }

    default:
      break;
  }
}
export function retrievePathTransitionsWithoutVariants(segments, mode) {
  let transitions = [];
  let from;
  let to;

  for (let index = 0; index < segments.length; index++) {
    const segment = segments[index];
    let currentSegmentTech = segment.tech;

    if (!from) {
      from = segment.globalIdxStart + segment.coordinates.length - 1;
    } else {
      to = from + 1;
      if (
        currentSegmentTech !== segments[index - 1]?.tech ||
        mode !== "VARIANT"
      ) {
        transitions.push({ from: from, to: to });
      }
      if (segment.type === "Tunnel" || segment.type === "T") {
        from = segment.globalIdxStart + 1;
      } else {
        from = segment.globalIdxStart + segment.coordinates.length - 1;
      }
    }
  }
  return transitions;
}

export function retrievePathTransitions(segments) {
  // First we add the first transition form the first node to the second
  // one (start and end of the path always start with transition).
  let transitions = [{ from: 0, to: 1, sameTech: false }];

  // Then let's calculate the middle transitions.
  let from;
  let to;

  for (let index = 0; index < segments.length; index++) {
    const segment = segments[index];

    let currentSegmentTech = segment.tech;

    if (!from) {
      from = segment.globalIdxStart + segment.coordinates.length - 1;
    } else {
      to = from + 1;
      let sameTech = false;
      if (currentSegmentTech === segments[index - 1]?.name) {
        sameTech = true;
      }
      transitions.push({ from: from, to: to, sameTech: sameTech });
      if (segment.type === "Tunnel" || segment.type === "T") {
        from = segment.globalIdxStart;
      } else {
        from = segment.globalIdxStart + segment.coordinates.length - 1;
      }
    }
  }

  // Finally we calculate the last transition from the before last and
  // last nodes  (start and end of the path always start with transition).
  const lastSegment = segments[segments.length - 1];
  const lastSegmentNodes = lastSegment?.coordinates.length - 1;
  const lastFrom = lastSegment.globalIdxStart + lastSegmentNodes;
  const lastTo = lastSegment.globalIdxStart + lastSegmentNodes + 1;

  transitions.push({
    from: lastFrom,
    to: lastTo,
    sameTech: false
  });
  return transitions;
}
/**
 * Method that given a set of coordinates and a terrain asset id, it calculates
 * the cartographic 3D points (elevations) for each point of the
 * coordinates set with the given terrain as reference.
 *
 * @param {object} coordinates Set of coordinates to calculate elevations from.
 * @param {number} terrainAsset Id of the current terrain asset (in context).
 *
 * @returns {array}
 *
 */
export async function calculateElevations(coordinates, terrainAssetId) {
  const cartoPathPoints = [];
  const pathPoints = coordinates;
  const pathLength = pathPoints.length;

  for (let i = 0; i < pathLength; i++) {
    // Store the cartographic point of the coordinates to calculate
    // elevations after the loop.
    cartoPathPoints.push(
      Cartographic.fromDegrees(
        ...point(pathPoints[i]).geometry.coordinates,
        new Cartographic()
      )
    );
  }

  const pathElevationPoints = await getCesiumElevatonPoints(
    cartoPathPoints,
    terrainAssetId
  );

  return pathElevationPoints;
}

/**
 * Method that given a list of nodes (coordinates), it
 * calculates the bearing from a node to the next node.
 *
 * Note: Bearing is the angle measured in degrees from the north line (0 degrees).
 *
 * @param {array} coordinateList Array of coordinates to calculate bearing from.
 *
 * @returns {array} Array of bearings in decimal degrees, between -180 and 180 degrees
 *                  (positive clockwise) for each node in the input list.
 */
export function calculateBearings(coordinateList) {
  const finalBearings = [];

  let from;
  let to;
  let nodeBearing;

  for (let i = 0; i < coordinateList.length; i++) {
    // Get start point.
    from = point(coordinateList[i]);
    // Get next point.
    if (i === coordinateList.length - 1) {
      // If it's the last, let's compare with the penultimate.
      to = point(coordinateList[i - 1]);

      // Calculate the angle from the last pylon to the pylon before.
      nodeBearing = bearing(from, to);
    } else {
      // Get the point for the next pylon.
      to = point(coordinateList[i + 1]);

      // Calculate the angle from the current pylon to the next one.
      nodeBearing = bearing(from, to);
    }

    // Store bearing to use later to position the pylons.
    finalBearings.push(nodeBearing);
  }
  return finalBearings;
}

/**
 * Method that given a segment of a path (or a full path) and an operation mode,
 * it looks for the pylon name in the metadata of the first node and fetches the
 * pylon model.
 *
 * If it doesn't find a pylon name in the metadata, it fetches a default pylon instead
 * and marks it in the "isDefault" var.
 *
 * @param {object} segment The segment of a path to retrieve pylons from.
 * @param {string} mode The operation mode: "TYPE", "TECH" or "VARIANT".
 * @param {array} availableTechnologies The array of available technologies in the company.
 *
 * @returns {array} Array with the pylon model and a boolean indicating if it's a default pylon.
 */
export async function retirevePylonModel(
  segment,
  mode,
  availableTechnologies,
  availableVariants
) {
  const firstNode = segment.metadata[0].node;
  let pylonModel;
  let isDefault = false;

  switch (mode) {
    case "TECH": {
      const technologyData = availableTechnologies?.find(
        tech => tech.name === firstNode.TECH
      );

      if (technologyData) {
        const nodeTech = await fetchTechnology(technologyData.id);
        if (nodeTech) {
          pylonModel = nodeTech.model_3d;
        }
      }

      if (!pylonModel?.glb_file) {
        isDefault = true;
        const defaultPylon = await axios.get("models/DefaultPylon.json");

        pylonModel = {
          id: 0,
          description: "",
          parameter_type: "",
          value: null,
          unit: "",
          thumbnail: defaultPylon.data.thumbnail,
          glb_file: defaultPylon.data.glb_file,
          metadata: {
            volt: defaultPylon.data.volt,
            lines: defaultPylon.data.lines,
            wires: defaultPylon.data.wires,
            height: defaultPylon.data.height,
            sag_arc_radius: defaultPylon.data.sag_arc_radius,
            minimum_distance_to_ground:
              defaultPylon.data.minimum_distance_to_ground
          }
        };
      }
      return [pylonModel, isDefault];
    }

    case "VARIANT": {
      const variantData = availableVariants?.find(
        tech => tech.name === firstNode.VARIANT
      );

      if (variantData) {
        const nodeTech = await fetchVariant(variantData.id);
        if (nodeTech) {
          pylonModel = nodeTech.model_3d;
        }
      }

      if (!pylonModel?.glb_file) {
        isDefault = true;
        const defaultPylon = await axios.get("models/DefaultPylon.json");

        pylonModel = {
          id: 0,
          description: "",
          parameter_type: "",
          value: null,
          unit: "",
          thumbnail: defaultPylon.data.thumbnail,
          glb_file: defaultPylon.data.glb_file,
          metadata: {
            volt: defaultPylon.data.volt,
            lines: defaultPylon.data.lines,
            wires: defaultPylon.data.wires,
            height: defaultPylon.data.height,
            sag_arc_radius: defaultPylon.data.sag_arc_radius,
            minimum_distance_to_ground:
              defaultPylon.data.minimum_distance_to_ground
          }
        };
      }
      return [pylonModel, isDefault];
    }
    default:
      break;
  }
}

export async function retirevePylonModels(segment, availableVariants) {
  const defaultPylon = await axios.get("models/DefaultPylon.json");

  const defaultPylonModel = {
    id: 0,
    description: "",
    parameter_type: "",
    value: null,
    unit: "",
    thumbnail: defaultPylon.data.thumbnail,
    glb_file: defaultPylon.data.glb_file,
    metadata: {
      volt: defaultPylon.data.volt,
      lines: defaultPylon.data.lines,
      wires: defaultPylon.data.wires,
      height: defaultPylon.data.height,
      sag_arc_radius: defaultPylon.data.sag_arc_radius,
      minimum_distance_to_ground: defaultPylon.data.minimum_distance_to_ground
    }
  };

  let fetchedVariants = new Map();
  fetchedVariants.set("fallback", defaultPylonModel);

  let pylonModels = [];
  for (let index = 0; index < segment.coordinates.length; index++) {
    let nodeVariant = segment.metadata[index].node.VARIANT;

    const variantData = availableVariants?.find(
      variant => variant.name === nodeVariant
    );

    if (variantData && !fetchedVariants.has(nodeVariant)) {
      const fetchedNodeVariant = await fetchVariant(variantData.id);
      fetchedVariants.set(nodeVariant, fetchedNodeVariant.model_3d);
    }

    if (fetchedVariants.has(nodeVariant)) {
      if (!fetchedVariants.get(nodeVariant)?.glb_file) {
        pylonModels.push(fetchedVariants.get("fallback"));
      } else {
        pylonModels.push(fetchedVariants.get(nodeVariant));
      }
    } else {
      pylonModels.push(fetchedVariants.get("fallback"));
    }
  }
  return pylonModels;
}
/**
 * Method that given a pylon model object, it retrieves its
 * connectors (lines) in an array.
 *
 * @param {object} pylonModel Object for the pylon model.
 *
 * @returns {array} Array of connectors (lines) in the pylon model.
 */
export function retrievePylonConnectors(pylonModel) {
  const connectionCables = [];
  for (let [
    crossarmConnectorIndex,
    val
  ] of pylonModel.metadata.lines.entries()) {
    // Elevation of the crossarm (that's the elevation of the connector).
    const crossarmConnectorElevation = val[0];

    // Lateral cables displacement (meter to kilometers).
    const crossarmConnectorShift = val[1] / 1000;

    connectionCables.push({
      crossarmConnectorIndex,
      crossarmConnectorElevation,
      crossarmConnectorShift
    });
  }
  return connectionCables;
}

/**
 * Method that given a list of coordinates (usually a list of pylon coordinates) and
 * a reference terrain asset id,  it calculates the cartigraphic3D position, the bearing
 * and the orientation of each of the points in the coordinates list.
 *
 * @param {array} coordinateList A list of coordinates (x,y).
 * @param {number} terrainAssetId The id of the terrain asset to calculate cartesian 3D positions.
 *
 * @returns {array} Array of objects with geographicPositions, cartesian3DPositions, orientation and bearing
 *                  for each point in the input list of coordinates.
 *
 * @example Return object:
 *
 * {
 *  idx: 0,
 *  globalIdx: 58,
 *  geographicPosition: [
 *      6.789904756482985,
 *      45.8722901805929
 *  ],
 *  cartesian3DPosition: {
 *      x: 4418733.280181184,
 *      y: 526112.4300161629,
 *      z: 4556843.042745178
 *  },
 *  bearing: 146.50279958193653,
 *  orientation: {
 *      x: -0.36548304728217695,
 *      y: 0.08675794759549948,
 *      z: -0.8700782657239615,
 *      w: 0.3191535871537935
 *  }
 * }
 *
 */
export async function calculatePylonPositions(coordinateList, terrainAssetId) {
  const bearings = calculateBearings(coordinateList);

  const cartesian3DPoints = await geographicToCartesian3D(
    coordinateList,
    terrainAssetId
  );

  const orientations = [];
  const geographicPoints = [];

  for (let index = 0; index < coordinateList.length; index++) {
    const position = cartesian3DPoints[index];
    const bearing = bearings[index];
    const cartographicPosition = coordinateList[index];

    let heading = CesMath.toRadians(bearing + 90);

    let hpr = new HeadingPitchRoll(heading);
    let orientation = Transforms.headingPitchRollQuaternion(position, hpr);
    orientations.push(orientation);
    geographicPoints.push(cartographicPosition);
  }
  return {
    geographicPositions: geographicPoints,
    cartesian3DPositions: cartesian3DPoints,
    orientations: orientations,
    bearings: bearings
  };
}

/**
 * Method that given a list of pylon data (obtained by calculatePylonPositions), a pylon
 * model and a segment start index, it creates an array of pylon pairs with its
 * corresponding information.
 *
 * @param {array} pylonsData Array of pylon data (obtained by calculatePylonPositions).
 * @param {object} pylonModel Object of the pylon model.
 * @param {number} segmentStartIdx Index for the start.
 *
 * @returns {array} Array of pylon pairs.
 *
 * @example The returning object for a pair will have the following properties:
 *
 *  {
 *    id: 0,
 *    fromNode: {...},
 *    toNode: {...},
 *    lines: [...],
 *    model: {...}
 *  }
 *
 */
export function generatePylonPairs(pylonsData, segmentStartIdx) {
  const pylonPairs = [];

  for (
    let index = 0;
    index < pylonsData.cartesian3DPositions.length - 1;
    index++
  ) {
    const cartesian3DPositionA = pylonsData.cartesian3DPositions[index];
    const bearingA = pylonsData.bearings[index];
    const orientationA = pylonsData.orientations[index];
    const geographicPositionA = pylonsData.geographicPositions[index];
    const modelA = pylonsData.pylonModels[index];

    const cartesian3DPositionB = pylonsData.cartesian3DPositions[index + 1];
    const bearingB = pylonsData.bearings[index + 1];
    const orientationB = pylonsData.orientations[index + 1];
    const geographicPositionB = pylonsData.geographicPositions[index + 1];
    const modelB = pylonsData.pylonModels[index + 1];

    pylonPairs.push({
      id: index,
      fromNode: {
        idx: index,
        globalIdx: segmentStartIdx + index,
        geographicPosition: geographicPositionA,
        cartesian3DPosition: cartesian3DPositionA,
        bearing: bearingA,
        orientation: orientationA,
        model: modelA,
        lines: modelA.metadata.lines,
        connectors: retrievePylonConnectors(modelA)
      },
      toNode: {
        idx: index + 1,
        globalIdx: segmentStartIdx + index + 1,
        geographicPosition: geographicPositionB,
        cartesian3DPosition: cartesian3DPositionB,
        bearing: bearingB,
        orientation: orientationB,
        model: modelB,
        lines: modelB.metadata.lines,
        connectors: retrievePylonConnectors(modelB)
      }
    });
  }
  return pylonPairs;
}

export function generateVariantTransitionPair(
  pylonsData,
  pylonModel,
  segmentStartIdx
) {
  const pylonPairs = [];

  for (
    let index = 0;
    index < pylonsData.cartesian3DPositions.length - 1;
    index++
  ) {
    const cartesian3DPositionA = pylonsData.cartesian3DPositions[index];
    const bearingA = pylonsData.bearings[index];
    const orientationA = pylonsData.orientations[index];
    const geographicPositionA = pylonsData.geographicPositions[index];

    const cartesian3DPositionB = pylonsData.cartesian3DPositions[index + 1];
    const bearingB = pylonsData.bearings[index + 1];
    const orientationB = pylonsData.orientations[index + 1];
    const geographicPositionB = pylonsData.geographicPositions[index + 1];

    pylonPairs.push({
      id: index,
      model: pylonModel,
      lines: pylonModel.metadata.lines,
      fromNode: {
        idx: index,
        globalIdx: segmentStartIdx + index,
        geographicPosition: geographicPositionA,
        cartesian3DPosition: cartesian3DPositionA,
        bearing: bearingA,
        orientation: orientationA
      },
      toNode: {
        idx: index + 1,
        globalIdx: segmentStartIdx + index + 1,
        geographicPosition: geographicPositionB,
        cartesian3DPosition: cartesian3DPositionB,
        bearing: bearingB,
        orientation: orientationB
      }
    });
  }
  return pylonPairs;
}

export function calculateConnectorDisplacement(
  pointCoordinates,
  shift,
  bearing,
  terrainElevation,
  offset,
  reverse
) {
  let fromPoint;
  try {
    const pointType = getType(pointCoordinates);

    pointType === "Point"
      ? (fromPoint = pointCoordinates)
      : (fromPoint = point(pointCoordinates));
  } catch {
    fromPoint = point(pointCoordinates);
  }

  // Direction in degrees, if the shift is negative it's 90º
  // otherwise, if positive, it's 270º.
  let direction;

  if (reverse) {
    direction = (shift < 0 ? 270 : 90) + bearing;
  } else {
    direction = (shift < 0 ? 90 : 270) + bearing;
  }

  // Calculate the point from the origin to the absShift position in
  // the corresponding direction.
  const pointGeo = transformTranslate(fromPoint, Math.abs(shift), direction)
    .geometry.coordinates;

  const pointCart3D = Cartesian3.fromDegrees(
    ...pointGeo,
    terrainElevation + offset
  );

  return pointCart3D;
}

/**
 * Method that calculates the points between 2 connectors of a pylon pair.
 *
 * Given the connector shift and the pylon bearing, it transforms the point
 * by shifting it the absolute value of the connector shift in the direction
 * of the bearing of the pylon.
 *
 * It doesn't calculate catenary, so the lines will be straight.
 *
 * @param {object} pylonPair Object with the pylon pair information.
 * @param {array} elevationPoints Elevation points for the path.
 * @param {object} connector Pylon connector data.
 * @param {boolean} isLast Flag marking if it's the last pair in the segment.
 *
 * @returns {array} Array of 2 cartesian 3D points (from connector to connector).
 *
 */
export function calculateOverheadCablePoints(
  pylonPair,
  elevationPoints,
  connector,
  isLast
) {
  let cablePoints = [];

  // Calculate first point ("from" Node in the pair).
  let pointCoordinates = pylonPair.fromNode.geographicPosition;

  const firstPointCart3D = calculateConnectorDisplacement(
    pointCoordinates,
    connector.crossarmConnectorShift,
    pylonPair.fromNode.bearing,
    elevationPoints[pylonPair.fromNode.globalIdx].height,
    connector.crossarmConnectorElevation
  );

  cablePoints.push(firstPointCart3D);

  // Calculate second point ("to" Node in the pair).
  pointCoordinates = pylonPair.toNode.geographicPosition;

  const secondPointCart3D = calculateConnectorDisplacement(
    pointCoordinates,
    connector.crossarmConnectorShift,
    pylonPair.toNode.bearing,
    elevationPoints[pylonPair.toNode.globalIdx].height,
    connector.crossarmConnectorElevation,
    isLast
  );

  cablePoints.push(secondPointCart3D);

  return cablePoints;
}

/**
 * Method to calculate the points of the line between the connectors
 * of two pylons with catenary.
 *
 * It recieves a pylonPair and a connector, for instance in the following
 * scheme, the method will handle the line from connector (a) in the fromPylon
 * to the connector (a) in the toPylon.
 *
 *     a           b
 *     ┌┐         ┌┐
 *     └┴────┼────┴┘
 *  c ┌┐     │     ┌┐ d
 *    └┴─────┼─────┴┘
 *           │
 *           │
 *           │
 *           │
 *
 * @param {object} pylonPair Object with the information of the 2 pylons to connect.
 * @param {array} elevationPoints The elevation points of the whole path.
 * @param {object} connector The specific connector to link.
 * @param {object} pylonModel The information about the pylon model.
 * @param {boolean} isLast A flag marking if it's the last pair in a segment of pylons.
 *
 * @returns {array}
 */
export function calculateOverheadCablePointsWithCatenary(
  pylonPair,
  elevationPoints,
  connectorIdx,
  pylonModel,
  isLast
) {
  const fromConnector = pylonPair.fromNode.connectors[connectorIdx];
  const toConnector = pylonPair.toNode.connectors[connectorIdx];
  // We start by defining the points from one pylon to the other
  // trough a central imaginary line connecting both pylons.
  const middleFrom = point(pylonPair.fromNode.geographicPosition);
  const middleTo = point(pylonPair.toNode.geographicPosition);
  const distanceMidleFromMidleTo =
    distance(middleFrom, middleTo, {
      units: "kilometers"
    }) * 1000;
  const absShift = Math.abs(fromConnector.crossarmConnectorShift);

  let direction;
  if (isLast) {
    direction =
      (fromConnector.crossarmConnectorShift < 0 ? 270 : 90) +
      pylonPair.fromNode.bearing;
  } else {
    direction =
      (fromConnector.crossarmConnectorShift < 0 ? 90 : 270) +
      pylonPair.fromNode.bearing;
  }

  // Then we move that point from the center of the pylon to the connector
  // that we are connecting.

  //   a                                  a
  //   │ ──────────────────────────────── │
  //   │                 ▲                │
  //  ┌┴┐                │               ┌┴┐
  //  └┬┘ ───────────────┴────────────── └┬┘
  //   │                                  │
  //   │                                  │
  //   b                                  b

  const shiftedFrom = transformTranslate(middleFrom, absShift, direction);

  // And we do the same with the second pylon.
  if (isLast) {
    direction =
      -(fromConnector.crossarmConnectorShift < 0 ? 270 : 90) +
      pylonPair.toNode.bearing;
  } else {
    direction =
      (fromConnector.crossarmConnectorShift < 0 ? 90 : 270) +
      pylonPair.toNode.bearing;
  }
  const shiftedTo = transformTranslate(middleTo, absShift, direction);

  // Now we calculate the catenary points between the two connectors.
  //
  // ..                                               ..
  // │  ...                                         ...│
  // │     .......                               ....  │
  // │           .......                      ....     │
  // │                 ......         ........         │
  // │                        ........                 │

  let distanceVar = distance(shiftedFrom, shiftedTo);
  let pylonsBearing = bearing(shiftedFrom, shiftedTo);
  let numSegments = 13;

  // Old catenary
  // And calculate the elevation of those new points.
  // let arcElevations = computeCatenary(
  //   pylonModel || null,
  //   distanceVar,
  //   numSegments
  // );

  const modelPosition = elevationPoints[pylonPair.fromNode.globalIdx];
  const modelNextPosition = elevationPoints[pylonPair.toNode.globalIdx];
  const aParam = 200;
  let arcElevations = computeCatenaryHeights(
    distanceMidleFromMidleTo,
    modelPosition?.height + fromConnector?.crossarmConnectorElevation,
    modelNextPosition?.height + toConnector?.crossarmConnectorElevation,
    aParam,
    numSegments
  );

  const catenaryLength = arcElevations.length;
  let positions = [];
  let pointElevation;

  // Finally we iterate each point in the catenary to
  // calculate its final position.
  for (let q = 0; q < catenaryLength; q++) {
    pointElevation = arcElevations[q];

    // Calculate the point in the catenary by transforming it
    // from the center of the fromPoint to the point in the segment
    // of catenary (forward).
    //
    //   ────────────────►│
    // │xx                │                          xx│
    // │ xxxx             │                       xxxx │
    // │    xxxx          │                   xxxx     │
    // │       xxx        │                xxxx        │
    // │          xxxxx  ┌┼┐        xxxxxxx            │
    // │               xx│x│xxxxxxxx                   │
    // │                 └─┘                           │
    // │                                               │
    // │                                               │

    let catenaryCentralPoint = transformTranslate(
      shiftedFrom,
      (distanceVar / (catenaryLength - 1)) * q,
      pylonsBearing
    );

    // Create Cartesian3 final array.ZZ
    let coordinates = catenaryCentralPoint.geometry.coordinates;
    positions.push(Cartesian3.fromDegrees(...coordinates, pointElevation));
  }

  return positions;
}

export function calculateOverheadCablePointsWithSimpleCatenary(
  fromCenterCoords,
  toCenterCoords,
  fromPylonBearing,
  toPylonBearing,
  fromConnectorShift,
  toConnectorShift,
  fromConnectorHeight,
  toConnectorHeight,
  fromCenterElevation,
  toCenterElevation,
  fromPylonModel,
  toPylonModel,
  isLast
) {
  // We start by defining the points from one pylon to the other
  // trough a central imaginary line connecting both pylons.
  const middleFrom = point(fromCenterCoords);
  const middleTo = point(toCenterCoords);
  const absShift = Math.abs(fromConnectorShift);

  let direction;
  if (isLast) {
    direction = (fromConnectorShift < 0 ? 270 : 90) + fromPylonBearing;
  } else {
    direction = (fromConnectorShift < 0 ? 90 : 270) + fromPylonBearing;
  }

  // Then we move that point from the center of the pylon to the connector
  // that we are connecting.

  //   a                                  a
  //   │ ──────────────────────────────── │
  //   │                 ▲                │
  //  ┌┴┐                │               ┌┴┐
  //  └┬┘ ───────────────┴────────────── └┬┘
  //   │                                  │
  //   │                                  │
  //   b                                  b

  const shiftedFrom = transformTranslate(middleFrom, absShift, direction);

  // And we do the same with the second pylon.
  if (isLast) {
    direction = -(fromConnectorShift < 0 ? 270 : 90) + toPylonBearing;
  } else {
    direction = (fromConnectorShift < 0 ? 90 : 270) + toPylonBearing;
  }
  const shiftedTo = transformTranslate(middleTo, absShift, direction);

  // Now we calculate the catenary points between the two connectors.
  //
  // ..                                               ..
  // │  ...                                         ...│
  // │     .......                               ....  │
  // │           .......                      ....     │
  // │                 ......         ........         │
  // │                        ........                 │

  let distanceVar = distance(shiftedFrom, shiftedTo);
  let pylonsBearing = bearing(shiftedFrom, shiftedTo);
  let numSegments = 25;

  // And calculate the elevation of those new points.
  let arcElevations = computeCatenary(
    fromPylonModel || null,
    distanceVar,
    numSegments
  );

  const catenaryLength = arcElevations.length;

  let fixProfileElevation = toCenterElevation - fromCenterElevation;
  let fixProfileElevationStep = fixProfileElevation / (catenaryLength - 1);

  let positions = [];
  let PointElevation;

  // Finally we iterate each point in the catenary to
  // calculate its final position.
  for (let q = 0; q < catenaryLength; q++) {
    PointElevation =
      fromConnectorHeight - arcElevations[q] + fromCenterElevation;

    PointElevation += fixProfileElevationStep * q;

    // Calculate the point in the catenary by transforming it
    // from the center of the fromPoint to the point in the segment
    // of catenary (forward).
    //
    //   ────────────────►│
    // │xx                │                          xx│
    // │ xxxx             │                       xxxx │
    // │    xxxx          │                   xxxx     │
    // │       xxx        │                xxxx        │
    // │          xxxxx  ┌┼┐        xxxxxxx            │
    // │               xx│x│xxxxxxxx                   │
    // │                 └─┘                           │
    // │                                               │
    // │                                               │

    let catenaryCentralPoint = transformTranslate(
      shiftedFrom,
      (distanceVar / (catenaryLength - 1)) * q,
      pylonsBearing
    );

    // Create Cartesian3 final array.ZZ
    let coordinates = catenaryCentralPoint.geometry.coordinates;
    positions.push(Cartesian3.fromDegrees(...coordinates, PointElevation));
  }

  return positions;
}
/**
 * Method to calculate the points in cartesian3D of an underground
 * line. The method gets the coordinate list of the line, the number
 * of points that we want to interpolate and the depth in meters of
 * the underground line.
 *
 * The interpolation is done to smooth the underground path curves.
 *
 * @param {array} coordinateList The coordinates of the line.
 * @param {number} numInterpolatedPoints The amount of point to interpolate.
 * @param {number} depth The amount of meters underground.
 *
 * @returns {array} Array of cartesian 3D points representing the underground
 *                  cable.
 */
export async function calculateUndergroundPoints(
  coordinateList,
  terrainAssetId,
  numInterpolatedPoints = 5,
  depth = -5
) {
  // Interpolate additional points.
  const interpolatedPoints = interpolatePoints(
    coordinateList,
    numInterpolatedPoints
  );

  // Convert from Geographic to Cartesian 3D with a -5 z-offset.
  const cartesian3DPoints = await geographicToCartesian3D(
    interpolatedPoints,
    terrainAssetId,
    depth
  );

  return cartesian3DPoints;
}

// Render Methods

/**
 * Method that renders a tunnel. It adds a new entity to the given Cesium
 * viewer that represents the tunnel defined by the given tunnelPositions with
 * a material of tunnelColor color and with the data included in the tunnelData
 * parameter.
 *
 * @param {array} tunnelPositions Array of Cartesian 3D coordinates.
 * @param {string} tunnelColor String with the rgb color.
 * @param {object} tunnelData Data to include in the Cesium entity.
 * @param {object} viewer Target Cesium viewer.
 */
export function renderTunnel(tunnelPositions, tunnelColor, tunnelData, viewer) {
  try {
    // Shape of the tunnel
    const shape = tunnelShape("hollow-cylinder", {
      outerRadius: 10.0,
      innerRadius: 8.0
    });

    // Color of the pipe.
    const material = getColorByString(tunnelColor, 0.9);

    // Retrieve the source for the path entities.
    const pathVectorEntities = getPathSourceEnities(viewer);
    pathVectorEntities.suspendEvents();

    // Add the tunnel entity.
    pathVectorEntities.add({
      group: tunnelData.pathId,
      name: "tunnel",
      data: {
        type: "T",
        techIndex: tunnelData.techIndex
      },
      polylineVolume: {
        positions: tunnelPositions,
        shape: shape,
        cornerType: CornerType.BEVELED,
        material: material
        // clippingPlanes: clippingPlaneCollection
      },
      polyline: {
        positions: tunnelPositions,
        width: 2,
        material: new PolylineDashMaterialProperty({
          color: material,
          gapColor: Color.TRANSPARENT,
          dashLength: 8.0,
          dashPattern: 255 // Use different patterns (binary) for different dash styles
        }),
        // clampToGround: true,
        distanceDisplayCondition: new CallbackProperty(function () {
          const undergroundEnabled = viewer.scene.globe.translucency.enabled;
          return new DistanceDisplayCondition(
            undergroundEnabled ? 0 : 10000000,
            10000000
          );
        }, false)
      }
    });

    pathVectorEntities.resumeEvents();
  } catch (error) {
    console.error(error);
  }
}

/**
 * Method that renders an underground line (pipe/trench). It adds a new entity
 * to the given Cesium viewer that represents the underground pipe defined by
 * the given pipePositions with a material of pipeColor color and with the
 * data included in the data parameter and with the shape set in pipeShape
 * (for instance, "square").
 *
 * @param {array} pipePositions Array of Cartesian 3D coordinates.
 * @param {string} color String with the rgb color.
 * @param {string} shape Code for the shape of the pipe.
 * @param {object} data  Data to include in the Cesium entity.
 * @param {object} viewer Target Cesium viewer.
 */
export function renderUndergroundLine(
  pipePositions,
  color,
  shape,
  data,
  viewer
) {
  try {
    // Shape of the pipe
    const entityShape = pipeShape(shape);

    // Color of the pipe.
    const material = getColorByString(color, 0.9);

    // Retrieve the source for the path entities.
    const pathVectorEntities = getPathSourceEnities(viewer);
    pathVectorEntities.suspendEvents();

    pathVectorEntities.add({
      group: data.pathId,
      name: "earthCable",
      data: {
        type: "U",
        techIndex: data.techIndex
      },
      polylineVolume: {
        positions: pipePositions,
        entityShape,
        material
      },
      polyline: {
        positions: pipePositions,
        width: 2,
        material,
        distanceDisplayCondition: new CallbackProperty(function () {
          const undergroundEnabled = viewer.scene.globe.translucency.enabled;
          return new DistanceDisplayCondition(
            undergroundEnabled ? 0 : 10000000,
            10000000
          );
        }, false)
      }
    });

    pathVectorEntities.resumeEvents();
  } catch (error) {
    console.error(error);
  }
}

/**
 * Method that -given a list of points in Cartesian3D and a data
 * object- renders a line entity to be used by the map tools (add pylon)
 * as a yellow auxiliary line along the overhead line.
 *
 * @param {array} linePoints Line points in Cartesian3D.
 * @param {object} data Information to add to the line.
 * @param {boolean} isSelected When true, the entity will be shown.
 * @param {object} viewer Target Cesium viewer.
 */
export function addEntityAuxLine(
  linePoints,
  data,
  options = { isSelected: false },
  viewer
) {
  const pathSource = getDatasourceLayerByName(viewer, optimalPathVectorName);
  const optimalPathVectorEntities = pathSource.entities;

  optimalPathVectorEntities.suspendEvents();
  optimalPathVectorEntities.add({
    name: "auxline",
    show: options.isSelected,
    properties: {
      pathId: data.pathId
    },
    polyline: {
      positions: linePoints,
      width: 15,
      material: new ColorMaterialProperty(Color.YELLOW.withAlpha(0.6)),
      clampToGround: true
    }
  });
  optimalPathVectorEntities.resumeEvents();
}

export function addEntityNoPylonsLine(
  linePoints,
  color,
  data,
  options = { isSelected: false },
  viewer
) {
  const material = getColorByString(color, 0.9);
  const pathSource = getDatasourceLayerByName(viewer, optimalPathVectorName);
  const optimalPathVectorEntities = pathSource.entities;

  optimalPathVectorEntities.suspendEvents();
  optimalPathVectorEntities.add({
    // group: data.pathId,
    name: "flatline",
    show: !options.showPylons,
    properties: {
      pathId: data.pathId
    },
    polyline: {
      positions: linePoints,
      width: 4,
      material: material,
      clampToGround: true
    }
  });
  optimalPathVectorEntities.resumeEvents();
}

/**
 * Method that renders a Pylon. It adds a new entity to the Cesium viewer
 * with the given position and orientation.
 *
 * @param {object} model The pylon model object.
 * @param {array} position The cartesian 3D coordinates to position the pylon.
 * @param {object} orientation The orientation of the pylon (Quaternion).
 * @param {object} data Data to include in the Cesium entity.
 * @param {object} options The rendering options.
 * @param {object} viewer Target Cesium viewer.
 */
export function addEntityPylon(
  model,
  position,
  orientation,
  data,
  options = { showPylons: true, isSelected: false },
  viewer
) {
  try {
    let hasTerrain = viewer.terrainProvider instanceof CesiumTerrainProvider;

    // Retrieve the source for the path entities.
    const pathVectorEntities = getPathSourceEnities(viewer);
    pathVectorEntities.suspendEvents();

    // Add the entity for the pylon.
    pathVectorEntities.add({
      group: data.pathId,
      name: data.name,
      show: true,
      data: {
        wires: model.metadata.wires,
        volt: model.metadata.volt,
        alt: data.height,
        height: model.metadata.height,
        serial: data.serial,
        techIndex: data.techIndex,
        globalIndex: data.globalIndex,
        type: "O"
      },
      position: position,
      orientation: orientation,
      model: {
        uri: model.glb_file,
        color: Color.LIGHTGREY,
        allowPicking: true,
        show: options.showPylons, // Use state value to prevent show pylons when the user edit path with pylons hidden
        distanceDisplayCondition: new DistanceDisplayCondition(1.0, 2000.0),
        heightReference: hasTerrain
          ? HeightReference.NONE
          : HeightReference.CLAMP_TO_GROUND
      },
      cylinder: {
        length: 90,
        topRadius: 12.0,
        bottomRadius: 12.0,
        material: Color.fromAlpha(Color.WHITE, 0.005),
        outlineWidth: 0
      },
      ellipse: {
        heightReference: HeightReference.RELATIVE_TO_GROUND,
        semiMinorAxis: 20,
        semiMajorAxis: 20,
        height: 2,
        extrudedHeight: 2,
        outline: true,
        outlineColor: Color.YELLOW,
        outlineWidth: 5,
        show: options.isSelected,
        material: Color.fromAlpha(Color.WHITE, 0.005),
        clampToGround: true
      }
    });

    pathVectorEntities.resumeEvents();
  } catch (error) {
    console.error(error);
  }
}

/**
 * Method that -given a list of points in Cartesian3D and a data
 * object- renders a line entity representing the overhead cable
 * between 2 points, usually the connectors of 2 pylons.
 *
 * @param {array} positions  Array of Cartesian 3D coordinates.
 * @param {string} color String with the rgb color.
 * @param {object} data Information to add to the cable entity.
 * @param {object} options The rendering options.
 * @param {object} viewer Target Cesium viewer.
 */
export function addEntityOverheadCable(
  positions,
  color,
  data,
  options = { showPylons: true },
  viewer
) {
  try {
    // Color of the pipe.
    const material = getColorByString(color, 0.9);

    // Retrieve the source for the path entities.
    const pathVectorEntities = getPathSourceEnities(viewer);
    pathVectorEntities.suspendEvents();

    pathVectorEntities.add({
      group: data.pathId,
      wireIndex: data.wireIndex,
      show: options.showPylons,
      serial: data.serial,
      name: "line",
      polyline: {
        positions: positions,
        clampToGround: false,
        width: 2,
        material: material
      }
    });

    pathVectorEntities.resumeEvents();
  } catch (error) {
    console.error(error);
  }
}

/**
 * Method to render a circle on the ground representing a transition
 * between two technology types (O, U, T).
 *
 * @param {object} viewer Target Cesium viewer.
 * @param {number} pathId Id of the path.
 * @param {object} node Data for the node where the transition takes place.
 * @param {object} nextNode Data for the next node in the transition.
 * @param {number} terrainAssetId Id of the current terrain asset (in context).
 * @param {string} color Circle color, defaults to GRAY.
 */
export async function addEntityCircleTransitionArea(
  viewer,
  pathId,
  node,
  nextNode,
  terrainAssetId,
  color = null
) {
  try {
    const from = point(node.coordinates);
    const to = point(nextNode.coordinates);

    const midPoint = midpoint(from, to);
    const radius = distance(midPoint, to);

    // Get the path source.
    const pathSource = getDatasourceLayerByName(viewer, optimalPathVectorName);
    if (!pathSource) {
      throw new Error(
        `DataSource layer named ${optimalPathVectorName} not found`
      );
    }

    // Get the path entities.
    let optimalPathVectorEntities = pathSource.entities;
    optimalPathVectorEntities.suspendEvents();

    // Set the color if the parameter exists, set it to GRAY otherwise.
    let material;
    if (color) {
      material = getColorByString(color, 0.3);
    } else {
      material = Color.GRAY.withAlpha(0.3);
    }

    const positions = await geographicToCartesian3D(
      [midPoint.geometry.coordinates],
      terrainAssetId
    );

    optimalPathVectorEntities.add({
      group: pathId,
      name: "transitionCircle",
      position: positions[0],
      ellipse: {
        semiMajorAxis: radius * 1000,
        semiMinorAxis: radius * 1000,
        material: material,
        heightReference: HeightReference.CLAMP_TO_GROUND
      }
    });

    // Resume events if it was suspended previously
    optimalPathVectorEntities.resumeEvents();
    viewer.scene.requestRender();
  } catch (error) {
    console.error("Error in drawBasicTransitionModel:", error);
  }
}

/**
 * Method to render a rectangle on the ground representing a transition
 * between two technology types (O, U, T).
 *
 * @param {object} viewer Target Cesium viewer.
 * @param {number} pathId Id of the path.
 * @param {object} node Data for the node where the transition takes place.
 * @param {object} nextNode Data for the next node in the transition.
 * @param {number} terrainAssetId Id of the current terrain asset (in context).
 * @param {string} color Circle color, defaults to GRAY.
 */
export async function addEntityRectangleTransitionArea(
  viewer,
  pathId,
  node,
  nextNode,
  terrainAssetId,
  color = null
) {
  try {
    const from = point(node.coordinates);
    const to = point(nextNode.coordinates);

    const totalDistance = distance(from, to);
    const width = totalDistance / 3;

    // Calculate bearings from the center to each side.
    const bearingValue = bearing(from, to);
    const perpBearing1 = (bearingValue + 90) % 360;
    const perpBearing2 = (bearingValue - 90 + 360) % 360;

    // Calculate the rectangle corners.
    const corner1 = destination(from, width / 2, perpBearing1);
    const corner2 = destination(from, width / 2, perpBearing2);
    const corner3 = destination(to, width / 2, perpBearing1);
    const corner4 = destination(to, width / 2, perpBearing2);

    const rectangleCoords = [
      corner1.geometry.coordinates,
      corner2.geometry.coordinates,
      corner4.geometry.coordinates,
      corner3.geometry.coordinates,
      corner1.geometry.coordinates
    ];

    const pathSource = getDatasourceLayerByName(viewer, optimalPathVectorName);

    if (!pathSource) return;

    const optimalPathVectorEntities = pathSource.entities;
    optimalPathVectorEntities.suspendEvents();

    const material = color
      ? getColorByString(color)
      : Color.GRAY.withAlpha(0.5);

    const positions = await geographicToCartesian3D(
      rectangleCoords,
      terrainAssetId
    );

    optimalPathVectorEntities.add({
      group: pathId,
      name: "transitionRectangle",
      polygon: {
        hierarchy: new PolygonHierarchy(positions),
        material: material,
        heightReference: HeightReference.CLAMP_TO_GROUND
      }
    });

    optimalPathVectorEntities.resumeEvents();
    viewer.scene.requestRender();
  } catch (error) {
    console.error("Error in addEntityRectangleTransitionArea:", error);
  }
}

/**
 * Method to render a basic 3D model representing the transition
 * between two sections of the path, that's where the technology
 * type changes (O, U or T).
 *
 * @param {object} viewer Target Cesium viewer.
 * @param {number} pathId Id of the path.
 * @param {object} node Data for the node where the transition takes place.
 * @param {object} nextNode Data for the next node in the transition.
 * @param {object} nodeElevation Terrain elevation at the point where the node is located.
 * @param {number} offset Offset distance to ground for the model.
 * @param {string} color Transition color.
 */
export function renderBasicTransitionModel(
  viewer,
  pathId,
  node,
  nextNode,
  nodeElevation,
  offset = 20,
  color
) {
  try {
    const modelURL = "/models/SimpleCube.glb";

    // Find the center to place the model.
    const { longitude, latitude, height } = nodeElevation;
    const center = Cartesian3.fromRadians(longitude, latitude, height - offset);

    // Get the path source.
    const pathSource = getDatasourceLayerByName(viewer, optimalPathVectorName);
    if (!pathSource) {
      throw new Error(
        `DataSource layer named ${optimalPathVectorName} not found`
      );
    }

    // Get the path entities.
    let optimalPathVectorEntities = pathSource.entities;
    optimalPathVectorEntities.suspendEvents();

    const orientation = getOrientation(
      node?.coordinates,
      nextNode.coordinates,
      center
    );

    // The default color is gray
    let modelColor = color ? getColorByString(color) : Color.GRAY;

    // Place the model in the paths source.
    optimalPathVectorEntities.add({
      group: pathId,
      name: "Connector",
      position: center,
      model: {
        uri: modelURL,
        scale: 0.1,
        minimumPixelSize: 1.0,
        maximumScale: 20000,
        color: modelColor,
        colorBlendMode: ColorBlendMode.MIX, // You can choose from MIX, REPLACE, HIGHLIGHT
        orientation: orientation
      }
    });

    // Resume events if it was suspended previously
    optimalPathVectorEntities.resumeEvents();
    viewer.scene.requestRender();
  } catch (error) {
    console.error("Error in drawBasicTransitionModel:", error);
  }
}

/**
 * Method to render a connector when a section path changes its
 * technology (same type).
 *
 * @param {object} viewer Target Cesium viewer.
 * @param {object} connector Node where the change of technology takes place.
 * @param {number} terrainAssetId Id of the current terrain asset (in context).
 * @param {string} color Path color.
 */
export async function createConnector(
  viewer,
  connector,
  terrainAssetId,
  color
) {
  try {
    const carto = Cartographic.fromDegrees(
      connector.coordinates[0],
      connector.coordinates[1],
      new Cartographic()
    );

    let elevationPoints = await getCesiumElevatonPoints(
      [carto],
      terrainAssetId
    );

    if (!elevationPoints || elevationPoints.length === 0) {
      throw new Error("No elevation data found for provided coordinates");
    }

    renderSphereConnector(viewer, elevationPoints[0], 2, 4, color);
  } catch (error) {
    console.error("Error in createConnector:", error);
  }
}

import React, { useContext } from "react";
import { getAllCapabilities } from "services/capabilities";

const DispatchContext = React.createContext();
const StateContext = React.createContext();

/**
 * Initial state for the Capabilities Context
 */
const initialState = {
  costModels: [],
  mcdaModels: [],
  routingModels: [],
  scenarioTypes: [],
  uiModules: [],
  projectCaps: [],
  scenarioCaps: [],
  isFetching: false,
  hasFetched: false,
  error: null
};

/**
 * Reducer with the actions to update the Capabilities Context state.
 * @param { object } state
 * @param { object } action
 * @returns
 */
function CapabilitiesReducer(state, action) {
  switch (action.type) {
    case "FETCHING_START": {
      return {
        ...state,
        isFetching: true,
        hasFetched: false,
        error: null
      };
    }
    case "FETCHING_SUCCESS": {
      const res = action.payload.results;

      return {
        ...state,
        costModels: res.available_cost_models,
        mcdaModels: res.available_mcda_models,
        routingModels: res.available_routing_models,
        scenarioTypes: res.available_scenario_types,
        uiModules: res.available_ui_modules,
        projectCaps: res.project_caps,
        scenarioCaps: res.scenario_caps,
        isFetching: false,
        hasFetched: true,
        error: null
      };
    }
    case "FETCHING_ERROR": {
      return {
        ...state,
        isFetching: false,
        hasFetched: false,
        error: action.payload.error
      };
    }
    case "MAPTOOLS_SET": {
      return {
        ...state,
        mapTools: action.payload.mapTools
      };
    }
    default: {
      return state;
    }
  }
}

/**
 * Provides all children with the state and the dispatch of the
 * Capabilities Context.
 *
 * @param { node } children
 * @returns node
 */
export default function CapabilitiesProvider({ children }) {
  const [state, dispatch] = React.useReducer(CapabilitiesReducer, initialState);

  React.useEffect(() => {
    if (!state.hasFetched && !state.error && !state.isFetching) {
      dispatch({
        type: "FETCHING_START"
      });
      getAllCapabilities()
        .then(res => {
          dispatch({
            type: "FETCHING_SUCCESS",
            payload: { results: res }
          });
        })
        .catch(err => {
          console.log(err);
          dispatch({
            type: "FETCHING_ERROR",
            payload: { error: typeof err === "object" ? err.message : err }
          });
        });
    }
  }, [state.error, state.hasFetched, state.isFetching]);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
}

/**
 * Hook to access and manage the state of the Capabilities Context
 */
const useCapabilities = () => {
  const capabilitiesState = useContext(StateContext);
  // const capabilitiesDispatch = useContext(DispatchContext);

  function isIncluded(group, capability) {
    let isCapable = false;
    const capabilitiesGroup = capabilitiesState[group];
    if (capabilitiesGroup instanceof Array) {
      isCapable = capabilitiesGroup.includes(capability);
    }
    return isCapable;
  }

  return {
    // Project Caps
    canAddProject: isIncluded("projectCaps", "ADD-PROJECT"),
    canEditProjectPoints: isIncluded("projectCaps", "EDIT-POINTS"),
    canDeleteProject: isIncluded("projectCaps", "DELETE-PROJECT"),
    canAddCategory: isIncluded("projectCaps", "ADD-CATEGORY"),
    canChangeCategory: isIncluded("projectCaps", "CHANGE-CATEGORY"),
    canDeleteCategory: isIncluded("projectCaps", "DELETE-CATEGORY"),
    canAddLayer: isIncluded("projectCaps", "ADD-LAYER"),
    canDownloadLayer: isIncluded("projectCaps", "DOWNLOAD-LAYER"),
    canAddBaseLayer: isIncluded("projectCaps", "ADD-BASELAYER"),
    canDeleteLayer: isIncluded("projectCaps", "DELETE-LAYER"),
    canBufferLayers: isIncluded("projectCaps", "BUFFER-LAYERS"),
    canAddBaseLayers: isIncluded("projectCaps", "ADD-BASELAYER"),
    canChangeLayer: isIncluded("projectCaps", "CHANGE-LAYER"),
    canExportProjectResults: isIncluded("projectCaps", "EXPORT-RESULTS"),
    // Scenario Caps
    canAddScenario: isIncluded("scenarioCaps", "ADD-SCENARIO"),
    canDeleteScenario: isIncluded("scenarioCaps", "DELETE-SCENARIO"),
    canChangecenario: isIncluded("scenarioCaps", "CHANGE-SCENARIO"),
    canAddCatalog: isIncluded("scenarioCaps", "ADD-CATALOG"),
    canLoadCatalog: isIncluded("scenarioCaps", "LOAD-CATALOG"),
    canScenarioSettings: isIncluded("scenarioCaps", "SCENARIO-SETTINGS"),
    canEditLayers: isIncluded("scenarioCaps", "EDIT-LAYERS"),
    canExportScenarioCvs: isIncluded("scenarioCaps", "EXPORT-CSV"),
    canImportScenarioCvs: isIncluded("scenarioCaps", "IMPORT-CSV"),
    canImportPath: isIncluded("scenarioCaps", "IMPORT-PATH"),
    canGenerateRM: isIncluded("scenarioCaps", "GENERATE-RM'"),
    canGenerateCOR: isIncluded("scenarioCaps", "GENERATE-COR'"),
    canGeneratePath: isIncluded("scenarioCaps", "GENERATE-PATH'"),
    canGenerateCMAP: isIncluded("scenarioCaps", "GENERATE-CMAP'"),
    // Scenario Types
    canUseClassicScenarios: isIncluded("scenarioTypes", "CLASSIC"),
    canUseMultiScenarios: isIncluded("scenarioTypes", "MULTI"),
    canUseChildScenarios: isIncluded("scenarioTypes", "MULTI-CHILD"),
    canUseOverheadScenarios: isIncluded("scenarioTypes", "MULTI-CHILD-OH"),
    canUseEarthCableScenarios: isIncluded("scenarioTypes", "MULTI-CHILD-EC"),
    canUseTunnelScenarios: isIncluded("scenarioTypes", "MULTI-CHILD-TUN"),
    canUseTunnelEntranceScenarios: isIncluded(
      "scenarioTypes",
      "MULTI-CHILD-TU-E"
    ),
    // UI Modules
    hasPathSections: isIncluded("uiModules", "panel-subsection"),
    hasPathSectionsEdit: isIncluded("uiModules", "panel-subsection-edit"),
    hasPathGeometryEdit: isIncluded("uiModules", "tools-geometry-edit"),
    hasCesiumAssets: isIncluded("uiModules", "panel-cesium-assets"),
    // Cost Models
    costModels: capabilitiesState.costModels
  };
};

export { CapabilitiesProvider, useCapabilities };
